import {
  createStylesParam,
  createStylesParams,
  StyleParamType,
  wixColorParam,
  wixFontParam,
  type IStyleParam,
} from '@wix/tpa-settings';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import {
  AUTHOR_INFO_TYPE_NAME_PICTURE,
  Layout,
  PgLayoutCardsRowTypeOptions,
  PostListWidgetPaginationType,
  PostListWidgetSliderArrowsPosition,
} from '@wix/communities-blog-client-common';
import { dangerousKeyTransformationOverride } from '@app/common/settings-components/helpers';
import {
  AlignmentValue,
  type GetIStylesParams,
  type StyleParamsValues,
} from '@app/common/style-params';
import {
  CategoryLabelType,
  CropType,
  HeightType,
  ImageAlignment,
  ImageRatio,
  VideoPlaybackSpeed,
  VideoPlayType,
} from './Settings/types';

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoType = createStylesParam('blog-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoTypeMobile = createStylesParam('blog-mobile-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

const getDefaultValueForRatingFont = wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 });

const STYLE_PARAMS = {
  // #region Settings - Desktop
  showFeaturedPostsOnly: createStylesParam<StyleParamType.Boolean>('postListWidgetIsFeatured', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  paginationType: createStylesParam<StyleParamType.Number>('postListWidgetPaginationType', {
    type: StyleParamType.Number,
    getDefaultValue: () => PostListWidgetPaginationType.NumberOfPosts,
  }),
  /** Range: 1-50. Enabled only when `paginationType` is `PostListWidgetPaginationType.NumberOfPosts` */
  postCount: createStylesParam<StyleParamType.Number>('postListWidgetEntityCount', {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  }),
  /** Range: 1-50. Enabled only when `paginationType` is `PostListWidgetPaginationType.Paginated`  */
  postsPerPage: createStylesParam<StyleParamType.Number>('postListWidgetPostsPerPage', {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  }),
  // #region Display - Desktop
  showAuthorName: createStylesParam<StyleParamType.Boolean>('blog-isAuthorNameEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showAuthorPicture: createStylesParam<StyleParamType.Boolean>('blog-isAuthorPictureEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(showLegacyAuthorInfoType) === AUTHOR_INFO_TYPE_NAME_PICTURE;
    },
  }),
  showPostPublishDate: createStylesParam<StyleParamType.Boolean>('blog-isPostPublishDateEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showReadingTime: createStylesParam<StyleParamType.Boolean>('blog-isReadingTimeEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCoverImage: createStylesParam<StyleParamType.Boolean>('blog-isCoverImageEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCategoryLabel: createStylesParam<StyleParamType.Boolean>('blog-isCategoryLabelEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showDescription: createStylesParam<StyleParamType.Boolean>('blog-isPostDescriptionEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPostRating: createStylesParam<StyleParamType.Boolean>('blog-isPostRatingEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCommentCount: createStylesParam<StyleParamType.Boolean>('blog-isCommentCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showViewCount: createStylesParam<StyleParamType.Boolean>('blog-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showLikeCount: createStylesParam<StyleParamType.Boolean>('blog-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // #region Display - Mobile
  isMobileDisplaySettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileDisplaySettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  showAuthorNameMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorNameEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showAuthorPictureMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorPictureEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: ({ getStyleParamValue }) => {
        return getStyleParamValue(showLegacyAuthorInfoTypeMobile) === AUTHOR_INFO_TYPE_NAME_PICTURE;
      },
    },
  ),
  showPostPublishDateMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostPublishDateEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showReadingTimeMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isReadingTimeEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showCoverImageMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCoverImageEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showCategoryLabelMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCategoryLabelEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showDescriptionMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostDescriptionEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showPostRatingMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostRatingEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showCommentCountMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCommentCountEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showViewCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showLikeCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Layout - Desktop
  layoutType: createStylesParam<StyleParamType.Number>('layout-post-list-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => Layout.PgGrid,
  }),
  layoutClassicCropType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutOneColumnCropTypeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutSideBySideCropTypeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutEditorialCropType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutSideBySideCropType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutClassicImageRatio: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutOneColumnImageRatioMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutSideBySideImageRatioMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutMagazineImageRatioMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutSliderImageRatioMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-slider-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutEditorialImageRatio: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutSliderImageRatio: createStylesParam<StyleParamType.Number>(
    'layout-post-list-slider-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutSideBySideImageRatio: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutMagazineImageRatio: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.OneByOne },
  ),
  textAlignment: createStylesParam<StyleParamType.Number>('contentAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => AlignmentValue.Start,
  }),
  layoutClassicImageAlignment: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-imageAlignment',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => ImageAlignment.End,
    },
  ),
  layoutSideBySideImageAlignmentMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-imageAlignment',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => ImageAlignment.End,
    },
  ),
  layoutSideBySideImageAlignment: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-imageAlignment',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => ImageAlignment.End,
    },
  ),
  /** Range: 20-80 */
  layoutClassicImageProportions: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-imageProportions',
    { type: StyleParamType.Number, getDefaultValue: () => 25 },
  ),
  /** Range: 20-80 */
  layoutSideBySideImageProportionsMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-imageProportions',
    { type: StyleParamType.Number, getDefaultValue: () => 25 },
  ),
  /** Range: 20-60 */
  layoutSideBySideImageProportions: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-imageProportions',
    { type: StyleParamType.Number, getDefaultValue: () => 50 },
  ),
  layoutSliderLoop: createStylesParam<StyleParamType.Boolean>('postListWidgetSliderLoop', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  layoutSliderLoopMobile: createStylesParam<StyleParamType.Boolean>(
    'postListWidgetSliderLoopMobile',
    { type: StyleParamType.Boolean, getDefaultValue: () => false },
  ),
  layoutSliderAutoplay: createStylesParam<StyleParamType.Boolean>('postListWidgetSliderAutoSlide', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  layoutSliderAutoplayMobile: createStylesParam<StyleParamType.Boolean>(
    'postListWidgetSliderAutoSlideMobile',
    { type: StyleParamType.Boolean, getDefaultValue: () => true },
  ),
  /** Range: 1-10 */
  layoutSliderAutoplayPauseTime: createStylesParam<StyleParamType.Number>(
    'postListWidgetSliderPauseTime',
    { type: StyleParamType.Number, getDefaultValue: () => 4 },
  ),
  /** Range: 1-10 */
  layoutSliderAutoplayPauseTimeMobile: createStylesParam<StyleParamType.Number>(
    'postListWidgetSliderPauseTimeMobile',
    { type: StyleParamType.Number, getDefaultValue: () => 4 },
  ),
  layoutSliderShowArrows: createStylesParam<StyleParamType.Boolean>(
    'postListWidgetSliderShowArrows',
    { type: StyleParamType.Boolean, getDefaultValue: () => true },
  ),
  layoutSliderShowArrowsMobile: createStylesParam<StyleParamType.Boolean>(
    'postListWidgetSliderShowArrowsMobile',
    { type: StyleParamType.Boolean, getDefaultValue: () => true },
  ),
  layoutSliderArrowsPosition: createStylesParam<StyleParamType.Number>(
    'postListWidgetSliderArrowsPosition',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => PostListWidgetSliderArrowsPosition.OnGallery,
    },
  ),
  layoutSliderArrowsPositionMobile: createStylesParam<StyleParamType.Number>(
    'postListWidgetSliderArrowsPositionMobile',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => PostListWidgetSliderArrowsPosition.OnGallery,
    },
  ),
  /** Range: 6-180 */
  layoutSliderArrowsSize: createStylesParam<StyleParamType.Number>(
    'postListWidgetSliderArrowsSize',
    { type: StyleParamType.Number, getDefaultValue: () => 18 },
  ),
  /** Range: 6-180 */
  layoutSliderArrowsSizeMobile: createStylesParam<StyleParamType.Number>(
    'postListWidgetSliderArrowsSizeMobile',
    { type: StyleParamType.Number, getDefaultValue: () => 18 },
  ),
  layoutSliderArrowsColor: createStylesParam<StyleParamType.Color>(
    'postListWidgetSliderArrowsColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  ),
  layoutSliderArrowsColorMobile: createStylesParam<StyleParamType.Color>(
    'postListWidgetSliderArrowsColorMobile',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  ),
  layoutClassicCardsPerRow: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutClassicCardsPerRowFitSize: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  /** Range: 1-5 */
  layoutClassicCardsPerRowFitCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutOneColumnCardsPerRowMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  layoutOneColumnCardsPerRowFitSizeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  layoutOneColumnCardsPerRowFitCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutSideBySideCardsPerRowMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  layoutSideBySideCardsPerRowFitSizeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  layoutSideBySideCardsPerRowFitCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutMagazineCardsPerRowMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  layoutMagazineCardsPerRowFitSizeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  layoutMagazineCardsPerRowFitCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutEditorialCardsPerRow: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutEditorialCardsPerRowFitSize: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  /** Range: 1-5 */
  layoutEditorialCardsPerRowFitCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutMagazineCardsPerRow: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutMagazineCardsPerRowFitSize: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 454 },
  ),
  /** Range: 1-5 */
  layoutMagazineCardsPerRowFitCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 0-100 */
  layoutClassicSpacing: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 12 },
  ),
  layoutOneColumnSpacingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  layoutSideBySideSpacingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  layoutMagazineSpacingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutEditorialSpacing: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutSideBySideSpacing: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutMagazineSpacing: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutOneColumnSpacing: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-one-column-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Readonly */
  layoutClassicHeightType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-300 */
  layoutClassicHeight: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 156 },
  ),
  layoutOneColumnHeightMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutOneColumnHeightTypeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  layoutSideBySideHeightMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutSliderHeightMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-slider-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutSliderHeightTypeMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-slider-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  layoutEditorialHeightType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Auto },
  ),
  /** Range: 0-300 */
  layoutEditorialHeight: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 156 },
  ),
  layoutSliderHeightType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-slider-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Auto },
  ),
  layoutSliderHeight: createStylesParam<StyleParamType.Number>(
    'layout-post-list-slider-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 156 },
  ),
  layoutOneColumnHeightType: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-one-column-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Auto },
  ),
  layoutOneColumnHeight: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-one-column-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 156 },
  ),
  layoutClassicHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 16 },
  ),
  layoutOneColumnHorizontalPaddingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 24 },
  ),
  layoutSideBySideHorizontalPaddingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 12 },
  ),
  layoutMagazineHorizontalPaddingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 12 },
  ),
  layoutSliderHorizontalPaddingMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-slider-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 24 },
  ),
  layoutEditorialHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 16 },
  ),
  layoutSliderHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'layout-post-list-slider-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 16 },
  ),
  layoutSideBySideHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 16 },
  ),
  layoutMagazineHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 30 },
  ),
  layoutOneColumnHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-one-column-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 16 },
  ),
  layoutClassicTitleLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-list-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutOneColumnTitleLineCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 2 },
  ),
  layoutOneColumnDescriptionLineCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-grid-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutSideBySideTitleLineCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-list-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 2 },
  ),
  layoutMagazineTitleLineCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-pg-text-on-image-medium-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 2 },
  ),
  layoutSliderTitleLineCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-slider-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 2 },
  ),
  layoutSliderDescriptionLineCountMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-post-list-slider-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutEditorialTitleLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutSliderTitleLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-slider-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutSideBySideTitleLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutMagazineTitleLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-text-on-image-medium-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutOneColumnTitleLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-one-column-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutEditorialDescriptionLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-grid-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 1 },
  ),
  layoutSliderDescriptionLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-slider-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 1 },
  ),
  layoutSideBySideDescriptionLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-side-by-side-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 1 },
  ),
  layoutOneColumnDescriptionLineCount: createStylesParam<StyleParamType.Number>(
    'layout-post-list-pg-one-column-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 1 },
  ),
  // #endregion
  // #region Layout - Mobile
  isMobileLayoutSettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileLayoutSettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  layoutTypeMobile: createStylesParam<StyleParamType.Number>('layout-mobile-post-list-layoutType', {
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => Layout.PgGrid,
  }),
  // #endregion
  // #region Design - Desktop - Posts
  /** @see usePostListFontSync */
  titleFont: createStylesParam<StyleParamType.Font>('post-post-list-titleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 14 }),
  }),
  titleColor: createStylesParam<StyleParamType.Color>('post-post-list-titleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  titleColorMobile: createStylesParam<StyleParamType.Color>('post-post-list-mobile-titleColor', {
    type: StyleParamType.Color,
    dangerousKeyTransformationOverride,
    getDefaultValue: wixColorParam('color-5'),
  }),
  /** @see usePostListFontSync */
  descriptionFont: createStylesParam<StyleParamType.Font>('post-post-list-descriptionFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),
  descriptionColor: createStylesParam<StyleParamType.Color>('post-post-list-descriptionColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  descriptionColorMobile: createStylesParam<StyleParamType.Color>(
    'post-post-list-mobile-descriptionColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-5'),
    },
  ),
  titleColorHover: createStylesParam<StyleParamType.Color>('post-post-list-linkHashtagColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  titleColorHoverMobile: createStylesParam<StyleParamType.Color>(
    'post-post-list-mobile-linkHashtagColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-8'),
    },
  ),
  layoutClassicTitleFont: createStylesParam<StyleParamType.Font>('post-list-post-list-titleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 14 }),
  }),
  layoutClassicDescriptionFont: createStylesParam<StyleParamType.Font>(
    'post-list-post-list-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Body-M', { size: 14 }) },
  ),
  layoutEditorialTitleFont: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-post-list-titleFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Page-title', { size: 22 }) },
  ),
  layoutEditorialDescriptionFont: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-post-list-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Body-M', { size: 16 }) },
  ),
  layoutSliderTitleFont: createStylesParam<StyleParamType.Font>('post-slider-post-list-titleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 22 }),
  }),
  layoutSliderTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-slider-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 22 },
  ),
  layoutSliderDescriptionFont: createStylesParam<StyleParamType.Font>(
    'post-slider-post-list-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Body-M', { size: 16 }) },
  ),
  layoutSideBySideTitleFont: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-post-list-titleFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Page-title', { size: 28 }) },
  ),
  layoutSideBySideTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-list-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 14 },
  ),
  layoutSideBySideDescriptionFont: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-post-list-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Body-M', { size: 16 }) },
  ),
  layoutMagazineTitleFont: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-post-list-titleFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Page-title', { size: 26 }) },
  ),
  layoutMagazineTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-pg-text-on-image-medium-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 22 },
  ),
  layoutMagazineTitleColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-titleColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  layoutMagazineTitleColorMobile: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-mobile-titleColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-1'),
    },
  ),
  layoutMagazineDescriptionFont: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-post-list-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Body-M', { size: 16 }) },
  ),
  layoutMagazineDescriptionColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  layoutMagazineDescriptionColorMobile: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-mobile-descriptionColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-1'),
    },
  ),
  layoutOneColumnTitleFont: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-post-list-titleFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Page-title', { size: 28 }) },
  ),
  layoutOneColumnTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-pg-grid-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 22 },
  ),
  layoutOneColumnDescriptionFont: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-post-list-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: wixFontParam('Body-M', { size: 16 }) },
  ),
  layoutOneColumnDescriptionFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-pg-grid-post-list-mobile-descriptionFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 16 },
  ),
  /** Hidden for "Magazine" layout only */
  borderColor: createStylesParam<StyleParamType.Color>('post-post-list-borderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 0.75),
  }),
  /** Hidden for "Magazine" layout only */
  borderColorMobile: createStylesParam<StyleParamType.Color>('post-post-list-mobile-borderColor', {
    type: StyleParamType.Color,
    dangerousKeyTransformationOverride,
    getDefaultValue: wixColorParam('color-2', 0.75),
  }),
  /** Hidden for "Magazine" layout only */
  borderWidth: createStylesParam<StyleParamType.Number>('post-post-list-borderWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  /** Hidden for "Magazine" layout only */
  borderWidthMobile: createStylesParam<StyleParamType.Number>('post-post-list-mobile-borderWidth', {
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => 1,
  }),
  /** Shown for "Magazine" layout only */
  overlayBackgroundColor: createStylesParam<StyleParamType.Color>('post-post-list-overlayColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.55),
  }),
  /** Shown for "Magazine" layout only */
  overlayBackgroundColorMobile: createStylesParam<StyleParamType.Color>(
    'post-post-list-mobile-overlayColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-5', 0.55),
    },
  ),
  /** Hidden for "Magazine" layout only */
  backgroundColor: createStylesParam<StyleParamType.Color>('post-post-list-backgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  }),
  /** Hidden for "Magazine" layout only */
  backgroundColorMobile: createStylesParam<StyleParamType.Color>(
    'post-post-list-mobile-backgroundColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-1', 1),
    },
  ),
  /** Range: 0-100 */
  borderRadius: createStylesParam<StyleParamType.Number>('post-post-list-postRadius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  borderRadiusMobile: createStylesParam<StyleParamType.Number>('post-post-list-mobile-postRadius', {
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => 0,
  }),
  areaBackgroundColor: createStylesParam<StyleParamType.Color>('postListWidgetBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  }),
  areaBackgroundColorMobile: createStylesParam<StyleParamType.Color>(
    'postListWidgetBackgroundColorMobile',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-5', 0),
    },
  ),
  // #endregion
  // #region Design - Desktop - Category labels
  categoryLabelType: createStylesParam<StyleParamType.Number>('category-label-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => CategoryLabelType.Text,
  }),
  categoryLabelTextFont: createStylesParam<StyleParamType.Font>('category-label-text-textFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 }),
  }),
  categoryLabelTextColor: createStylesParam<StyleParamType.Color>('category-label-text-textColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  categoryLabelTextColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-text-textColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8', 0.7) },
  ),
  categoryLabelTextUppercase: createStylesParam<StyleParamType.Boolean>(
    'category-label-text-uppercase',
    { type: StyleParamType.Boolean, getDefaultValue: () => false },
  ),
  /** Range: 0-100 */
  categoryLabelButtonHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'category-label-button-horizontalPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 12 },
  ),
  /** Range: 0-100 */
  categoryLabelButtonVerticalPadding: createStylesParam<StyleParamType.Number>(
    'category-label-button-verticalPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 6 },
  ),
  categoryLabelButtonFont: createStylesParam<StyleParamType.Font>(
    'category-label-button-textFont',
    {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 }),
    },
  ),
  categoryLabelButtonTextColor: createStylesParam<StyleParamType.Color>(
    'category-label-button-textColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  categoryLabelButtonTextColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-button-textColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  categoryLabelButtonUppercase: createStylesParam<StyleParamType.Boolean>(
    'category-label-button-uppercase',
    { type: StyleParamType.Boolean, getDefaultValue: () => false },
  ),
  categoryLabelButtonBackgroundColor: createStylesParam<StyleParamType.Color>(
    'category-label-button-backgroundColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  categoryLabelButtonBackgroundColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-button-backgroundColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8', 0.7) },
  ),
  /** Range: 0-100 */
  categoryLabelButtonCornerRadius: createStylesParam<StyleParamType.Number>(
    'category-label-button-cornerRadius',
    { type: StyleParamType.Number, getDefaultValue: () => 0 },
  ),
  categoryLabelButtonBorderColor: createStylesParam<StyleParamType.Color>(
    'category-label-button-borderColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  categoryLabelButtonBorderColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-button-borderColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8', 0.7) },
  ),
  /** Range: 0-10 */
  categoryLabelButtonBorderWidth: createStylesParam<StyleParamType.Number>(
    'category-label-button-borderWidth',
    { type: StyleParamType.Number, getDefaultValue: () => 0 },
  ),

  // #endregion
  // #region Design - Desktop - Ratings
  ratingsStarFilledColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-ratingFilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  ratingsStarEmptyColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-ratingUnfilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  ),
  ratingsLayoutMagazineStarFilledColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-ratingFilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  ratingsLayoutMagazineStarEmptyColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-ratingUnfilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  ),
  ratingsFontColor: createStylesParam<StyleParamType.Color>('post-post-list-ratingFontColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  ratingsLayoutMagazineFontColor: createStylesParam<StyleParamType.Color>(
    'post-post-list-TOI-ratingFontColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  ratingsLayoutClassicFont: createStylesParam<StyleParamType.Font>(
    'post-list-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutEditorialFont: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutOneColumnFont: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutSideBySideFont: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutMagazineFont: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutSliderFont: createStylesParam<StyleParamType.Font>(
    'post-slider-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyListFont: createStylesParam<StyleParamType.Font>('post-post-list-ratingFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  }),
  /** @deprecated */
  ratingsLayoutLegacyListMediumFont: createStylesParam<StyleParamType.Font>(
    'post-list-medium-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyListLargeFont: createStylesParam<StyleParamType.Font>(
    'post-list-large-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyPgIntermediateFont: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-intermediate-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyPgLargeFont: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-large-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyPgMediumFont: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyPgSideBySideFont: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-right-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyPgTextOnImageLargeFont: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-large-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  /** @deprecated */
  ratingsLayoutLegacyPgTextOnImageSmallFont: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-small-post-list-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  // #endregion
  // #region Design - Desktop - Video Ratings
  videoSettingsPlayType: createStylesParam<StyleParamType.Number>('post-list-video-play', {
    type: StyleParamType.Number,
    getDefaultValue: () => VideoPlayType.OnHover,
  }),
  videoSettingsSoundEnabled: createStylesParam<StyleParamType.Boolean>('post-list-video-sound', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  videoSettingsPlaybackSpeed: createStylesParam<StyleParamType.Number>(
    'post-list-video-playbackSpeed',
    { type: StyleParamType.Number, getDefaultValue: () => VideoPlaybackSpeed.Normal },
  ),
  videoSettingsLoopEnabled: createStylesParam<StyleParamType.Boolean>('post-list-video-loop', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  videoSettingsShowPlayButton: createStylesParam<StyleParamType.Boolean>(
    'post-list-video-showPlayButton',
    { type: StyleParamType.Boolean, getDefaultValue: () => true },
  ),
  // #endregion
  // #region Design - Mobile
  isMobileDesignSettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileDesignSettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  // #endregion
} as const satisfies {
  [key: string]: Omit<IStyleParam, 'name'>;
};

type IStylesParams = GetIStylesParams<typeof STYLE_PARAMS>;

export const customCssVars: CustomCssVarsFn<StyleParamsValues<IStylesParams>> = () => {
  return {};
};

export default createStylesParams<IStylesParams>(STYLE_PARAMS);
