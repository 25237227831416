import type { SiteTextPresets } from '@wix/platform-editor-sdk';
import type { ISiteTextPreset, IWixStyleFont } from '@wix/tpa-settings';
import { type PlatformQuery } from '@wix/ambassador-blog-v3-tag/types';

type Presets = ISiteTextPreset | SiteTextPresets;
export const getFontDisplayName = ({
  presets,
  preset,
  customFontLabel,
}: {
  presets?: Presets;
  preset?: string;
  customFontLabel: string;
}) => {
  if (preset === 'Custom' || !preset) {
    return customFontLabel;
  }

  const font = presets?.[preset as keyof Presets];

  if (!font) {
    return customFontLabel;
  }

  if ('displayName' in font) {
    return font.displayName;
  }

  if ('editorKey' in font) {
    return preset;
  }

  return customFontLabel;
};

/** Inspired by @wix/tpa-settings https://github.com/wix-private/tpa-settings/blob/75e6ff0e2f1bee7fbecdd5df706401e6e686e291/packages/tpa-settings/src/components/FontAndColorPicker/BaseFontAndColorPicker.tsx#L61 */
export const getFontForFontPicker = ({
  font,
  presets,
}: {
  font: IWixStyleFont;
  presets?: ISiteTextPreset;
}) => {
  if (!font.preset || font.preset === 'Custom') {
    return font;
  }

  const preset = presets?.[font.preset as keyof ISiteTextPreset];

  return preset
    ? {
        editorKey: preset.editorKey,
        ...font,
      }
    : font;
};

/**
 * A site text style has "size" defined as a string, e.g. "18px"
 *
 * Inspired by @wix/tpa-settings:
 * - https://github.com/wix-private/tpa-settings/blob/9dab5271849e6a4cec04e9515ea12b27ec8edba7/packages/tpa-settings/src/components/FontPicker/FontPicker.tsx#L28-L33
 * - https://github.com/wix-private/tpa-settings/blob/70fcc8312710e1f6109b8dd034039803d478a596/packages/tpa-settings/src/components/FontAndColorPicker/BaseFontAndColorPicker.tsx#L42-L59
 **/
export const prepareFontParamChangeValue = (
  obj: IWixStyleFont & { displayName?: string },
): IWixStyleFont => {
  return {
    ...obj,
    fontStyleParam: true,
    preset: obj.displayName ? obj.preset : 'Custom',
    size: typeof obj.size === 'string' ? parseInt(obj.size, 10) : obj.size,
    value: '',
  };
};

/** Prevents mobile parameters being saved as `"param▶︎m"` when changing values on Mobile breakpoint */
export const dangerousKeyTransformationOverride = (key: string) => key;

export class PlatformQueryBuilder {
  #query = {
    filter: { $and: [] as Record<string, any>[] },
    paging: { limit: 20, offset: 0 },
  } satisfies PlatformQuery;

  withFilter(filter: Record<string, any>) {
    this.#query.filter.$and.push(filter);
    return this;
  }

  withPage(page: number, pageSize: number) {
    this.#query.paging.limit = pageSize;
    this.#query.paging.offset = (page - 1) * pageSize;
    return this;
  }

  withOnlyId(id: string | undefined) {
    if (id) {
      this.#query.filter.$and.push({ id });
    }
    return this;
  }

  withoutId(id: string | undefined) {
    if (id) {
      this.#query.filter.$and.push({ id: { $ne: id } });
    }
    return this;
  }

  build() {
    return {
      ...this.#query,
      filter:
        this.#query.filter.$and.length > 1
          ? { $and: this.#query.filter.$and }
          : this.#query.filter.$and[0],
    };
  }
}

export function assert(condition: unknown, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
